
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isSelectOptions, SelectValue, SelectOption } from '@/components/form/form.types';
import { randId } from '@/utils/components';
import HelpTooltip from '@/components/HelpTooltip.vue';

@Component({
  components: {
    HelpTooltip,
  },
})
export default class GRadioGroup extends Vue {
  @Prop({ type: String, required: false }) readonly value?: SelectValue;
  @Prop({ type: Array, required: true }) readonly options!: SelectOption[] | string[];
  @Prop({ type: String, default: 'r' }) readonly name!: string;
  @Prop(Boolean) readonly inline!: boolean;
  @Prop(Boolean) readonly primary!: boolean;
  @Prop({ type: Boolean, default: undefined }) readonly valid?: boolean;

  get randName() {
    return `${this.name}_${randId()}`;
  }

  isChecked(option: SelectOption) {
    return option.value === this.value;
  }

  /**
   * workaround for not being able to add options after render
   */
  get key() {
    return JSON.stringify(this.options);
  }

  get formattedOptions(): SelectOption[] {
    if (!isSelectOptions(this.options)) {
      return this.options.map(option => {
        return {
          key: option,
          value: option,
          label: option,
          disabled: false,
        };
      });
    }

    return this.options.map(option => {
      const out = { ...option };
      if (!out.key) {
        out.key = out.value;
      }
      if (!out.label) {
        out.label = out.value;
      }
      return out;
    });
  }

  get eventHandlers() {
    return {
      change: this.input,
      blur: (e: Event) => this.$emit('blur', e),
    };
  }

  // currently gsk-radio-group emits 3 "change" events...
  input(e: CustomEvent): void {
    if (e.detail === undefined) {
      // skip the native event that does not have e.detail set
      return;
    }
    let {
      detail: { value },
    } = e;
    if (Array.isArray(value)) {
      value = value[0];
    }
    const opt = this.formattedOptions.find(option => value === option.value);

    this.$emit('input', opt?.value ?? '');
    this.$emit('change', opt?.value ?? '');
  }
}
